/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.nav-primary .expand_arrow').click(function(){
      		$(this).parent().toggleClass('open');
      	});
    		$( ".accessibility .bigger" ).click(function() {
    			$("body").css( "font-size", "+=2" );
    		});
    		$( ".accessibility .acc-text" ).click(function() {
    			$("body").css( "font-size", "+=2" );
    		});
    		$( ".accessibility .smaller" ).click(function() {
    			$("body").css( "font-size", "-=2" );
    		});
    		$( ".accessibility .default-font-size" ).click(function() {
    			$("body").css( "font-size", "18px" );
    		});
        function getCookie(c_name)
          {
            var c_value = document.cookie;
            var c_start = c_value.indexOf(" " + c_name + "=");
            if (c_start === -1)
            {
                c_start = c_value.indexOf(c_name + "=");
            }
            if (c_start === -1)
            {
                c_value = null;
            }
            else
            {
                c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end === -1)
            {
                c_end = c_value.length;
            }
            c_value = unescape(c_value.substring(c_start,c_end));
            }
            return c_value;
          }
          function setCookie(c_name,value,exdays) {
              var exdate=new Date();
              exdate.setDate(exdate.getDate() + exdays);
              var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString()) + ";path=/";
              document.cookie=c_name + "=" + c_value;
          }
          var ifContrastCookie = function(){
            var contrast=getCookie("contrast");
            if (contrast==="high") {
              $('body').addClass('contrast-enabled');
            }
          };
          ifContrastCookie();
          jQuery(".contrast" ).click(function() {
            var contrast=getCookie("contrast");
            if (contrast===null || contrast==="normal")
              {
                setCookie("contrast",'high',1);
                $('html').addClass('hContrast');
              }
              else{
                setCookie("contrast",'normal',1);
                $('html').removeClass('hContrast');
              }
          });
        jQuery(".font-size span" ).click(function() {
            setTimeout(function(){
            var fontSize = jQuery("body").css("font-size");
                setCookie("fontsize",fontSize,1);

             }, 500 );
        });
        $('.mobile-menu-btn button').click(function(){
          $('.nav-primary, .content-top').toggle(200);
        });
        jQuery(document).ready(function () {
          //var maps = $('iframe[src^="https://www.google.com/maps"]');
          var maps = $('.map');
           maps.find('iframe').css('pointer-events', 'none');
           maps.each(function(){
             $( this ).mousedown(function(e) {
                 jQuery(this).find('iframe').css('pointer-events', '');
              });
              $( this ).mouseleave(function(e) {
                  jQuery(this).find('iframe').css('pointer-events', 'none');
               });
           });
       });
       $(window).load(function () {
         $('.gallery ul').collagePlus({
           'targetHeight'    : 200
         });
        });
        var $allImageLinks =$('a[href$=".jpg"]:not(.no-fancy),a[href$=".jpeg"]:not(.no-fancy),a[href$=".png"]:not(.no-fancy), .fancylink');
        $allImageLinks.fancybox({
          helpers	: {
      			title	: {
      				type: 'inside'
      			},
      			thumbs	: {
      				width	: 100,
      				height	: 100
      			}
      		}
        });

        $('#menu-main-menu > li').each(function(){
          var a = $(this);
          if (a.find('.current-post-ancestor, .current-page-ancestor, .current-menu-ancestor, .current-menu-parent, .current-page-parent, .current_page_parent, .current-menu-item, .current_page_item, .current_page_ancestor, .current-category-ancestor' ).length) {
            a.addClass('current-menu-parent');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
